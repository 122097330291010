import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './PrivacyStatement.css';
import { checkAndUpdateLanguage } from '../i18n/i18n';

const PrivacyStatement = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const language = checkAndUpdateLanguage(i18n.language);
    console.log('Privacy Statement component rendered. Current language:', language);

    const handleLanguageChange = () => {
      console.log('Language change event detected in Privacy Statement');
      checkAndUpdateLanguage(i18n.language);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, [i18n.language]);

  return (
    <Container className="privacy-statement">
      <h1>{t('Personal Information Collection Statement')}</h1>

      <p>{t('Macau.ai respects the privacy of individuals. We do our best to ensure the collection, usage, storage, transfer and disclosure of your personal data is in accordance with the Personal Data (Privacy) Ordinance. We ensure our employees comply with the requirements of this Statement and the')} <Link to="/privacy">{t('Privacy Policy Statement')}</Link>.</p>

      <ol>
        <li>{t('This is a personal data collection statement made under the Personal Data (Privacy) Ordinance ("the Ordinance").')}</li>
        <li>{t('Information collected when you visit our websites:')}
          <ul>
            <li>{t('Use of cookies - When you browse this website, cookies will be stored in your computer\'s hard drive. The purpose of using cookies is to facilitate the successful redirection to the correct page upon your clicking on the changing banner. We do not collect or store any personal data from you under this circumstance. You have a choice not to accept the cookies, but if you do, certain functionality may not be available.')}</li>
            <li>{t('Statistics on visitors to our websites - We will record your visit only as a "hit". The webserver makes a record of your visit that includes your IP addresses (and domain names), the types and configurations of browsers, language settings, geo-locations, operating systems, previous sites visited, and time/duration and the pages visited (webserver access log).')}</li>
          </ul>
        </li>
        <li>{t('We use the webserver access log for the purpose of maintaining and improving our websites.')}</li>
        <li>{t('We do not use, and have no intention to use the visitor data to personally identify anyone.')}</li>
        <li>{t('The information collected by means of cookies will be used only for compiling aggregate statistics on how visitors browse the website.')}</li>
        <li>{t('Your name and address will be used by our website for sending you newsletters and printed material about our recreational events for promoting Time Bank.')}</li>
        <li>{t('An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. After approval of your comment, your profile picture is visible to the public in the context of your comment.')}</li>
        <li>{t('If you upload images to the website, avoid uploading images with embedded location data (EXIF GPS) included.')}</li>
        <li>{t('If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies.')}</li>
        <li>{t('If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies.')}</li>
        <li>{t('When you log in, we will also set up several cookies to save your login information and your screen display choices.')}</li>
        <li>{t('Articles on this site may include embedded content from other websites.')}</li>
        <li>{t('These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content.')}</li>
        <li>{t('You have the right to request access to and correction of information held by us about you.')}</li>
        <li>{t('We are committed to protecting the privacy, confidentiality and security of the personal information we hold.')}</li>
        <li>{t('We pledge to comply with the requirements of the Personal Data (Privacy) Ordinance.')}</li>
      </ol>
      <br></br>

      <section aria-labelledby="disclaimer-heading">
        <h1 id="disclaimer-heading">{t('Disclaimer')}</h1>
        <p>{t('The information provided in this guidance is for general reference only and does not provide an exhaustive guide to the application of the Personal Data (Privacy) Ordinance. Direct reference should be made to the Ordinance itself for a complete and definitive statement of law. The Commissioner makes no express or implied warranties of accuracy or fitness for a particular purpose or use with respect to the above information.')}</p>
      </section>
      <br></br>

      <section aria-labelledby="contact-heading">
        <h2 id="contact-heading">{t('Contact Us')}</h2>
        <p>{t('If you have any questions or concerns about our personal information policies or practices, please contact our data protection officer:')}</p>
        <address>
          <a href="mailto:info@macau.ai" rel="noopener noreferrer">info@macau.ai</a>
        </address>
      </section>
      <br></br>
      <br></br>
    </Container>
  );
};

export default PrivacyStatement;